import ErrorTemplate from 'components/ErrorTemplate'

export default function Custom404() {
  return (
    <ErrorTemplate
      title="Page Not Found"
      description="It might be an old link or the page has moved"
    />
  )
}
